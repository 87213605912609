
import { Image } from '@chakra-ui/react';

const DesktopWidth = 180
const MobileWidth = 150

interface SiteLogoProps { logoFileName: string}

export const SiteLogo = ({logoFileName}: SiteLogoProps) => {
    const companyLogo = require(`../../media/${logoFileName}`); // this is sync

    return (
        <Image 
            display={'flex'} 
            alignItems={'center'} 
            justifyContent={'center'}
            width={[MobileWidth, null, DesktopWidth]}
            height={[MobileWidth / 1.6, null, DesktopWidth / 1.6]}
            alt="Company Logo"
            src={companyLogo}
            py={['0.8rem', null, '1rem']}
            px={['0.8rem', null, '1rem']}
        />
    )
}
