import { Box, Text, } from "@chakra-ui/react"
import { Link } from "react-scroll"
import SiteLink from "../../models/SiteData/SiteLink"

const HeaderLink = ({label, url}: SiteLink) => {
    return (
        <>
            <Box className='NavigationLink' _hover={{color : 'orange'}} pr='8px'>
                <Link to={(url) ? url : ''}>
                    <Text fontSize={['12px', null, '20px']}>{label}</Text>
                </Link>
            </Box>
        </>
    )
}

export default HeaderLink