
interface CarouselVideoProps {
    filePath : string
    videoHeight: string
}


export const CarouselVideo = ({filePath, videoHeight}: CarouselVideoProps) => {
    return (
        <video autoPlay muted loop style={{objectFit : 'cover', height: videoHeight, width:'100%'}}>
            <source src={filePath} type='video/mp4'/>
            Your browser does not support the video tag.
        </video>
    )
}

