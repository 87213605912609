import { useContext } from "react"
import { siteSettingsContext } from "../../context/SiteSettingsContext"
import { GridItem, Text } from "@chakra-ui/react"
import InfoTile from "../InfoTile/InfoTile"
import { Box } from "@chakra-ui/react"
import * as wallImage from '../../media/wall-painting.jpg'
import { Image } from "@chakra-ui/react"
import useImage from "../../hooks/useImage"

interface SiteBodyProps {
    fadeOffset : string
}

const SiteBody = ({fadeOffset} : SiteBodyProps) => {
    const { siteBody } = useContext(siteSettingsContext)

    const roofImage = useImage('roof-tiling.jpg')
    const roofImageSource = (roofImage.image) ? roofImage.image : ''

    // const wallImage = useImage('wall-painting-2.png')
    const wallImage = useImage('wall-painting-3.png')
    // const wallImage = useImage('wall-painting.jpg')
    const wallImageSource = (wallImage.image) ? wallImage.image : ''

    const fundingImage = useImage('funding-2.png')
    const fundingImageSource = (fundingImage.image) ? fundingImage.image : ''

    const sources = [roofImageSource, wallImageSource, fundingImageSource]


    return (
        <Box>
        {
            siteBody.sections.map((sectionData, i) => {
                return (
                    <>
                    <GridItem 
                        py={['0.5rem', null, '3rem']}
                        px='50px'
                        colStart={1}
                        colSpan={[2, null, 4]} 
                        backgroundColor={(i % 2 === 0) ? 'white' : "rgb(255, 238, 186)"}
                        >
                        <InfoTile sectionData={sectionData} fadeOffset={fadeOffset}/>
                    </GridItem>
                    {(sources.length > i) && 
                        <Image 
                            src={sources[i]}
                            objectFit='cover'
                            // objectPosition={ (i === 2) ? '0px -575px' : '100% -260px'}
                            w={'100%'}
                            h={['100px' ,'300px']}
                        />
                    }
                    </>
                )
            })
        }
        </Box>
    )
}


export default SiteBody