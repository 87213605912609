import { Text, HStack, Image, Box, ListItem, UnorderedList } from "@chakra-ui/react";
import SiteBodySectionData from "../../models/SiteData/SiteBodySectionData";
import useImage from "../../hooks/useImage";
import { Flex } from "@chakra-ui/react";

interface InfoTileProps  {
    sectionData : SiteBodySectionData
    fadeOffset : string
}
const InfoTile = ({sectionData, fadeOffset}: InfoTileProps) => {

    const {imageFileName, title, bodyText, imageWidth, bodyTextList} = sectionData
    const tileImage = useImage(imageFileName)
    const tileImageSource = (tileImage.image) ? tileImage.image : ''

    return (
        <div 
            data-aos='fade-up'
            data-aos-offset={fadeOffset}
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-anchor-placement="top-center"
        >
        <HStack 
            className='infoRow' 
            px={[0, 0, '30px']}
            id={`#${title.toLowerCase().replace(' ', '-')}`}
        >
                
            <Box h='100%'>
                {tileImageSource && <Image
                    borderRadius='12px'
                    width={imageWidth}
                    alt="171x180"
                    src={tileImageSource}
                    display={['None', null, 'flex']}
                    />}
            </Box>
            <Flex direction={'column'} pl={[0, 0, '18px']}>
                <Text mb={['0rem', null, '0rem']} fontSize={['20px', null, '40px']}>{title}</Text>
                <Text mb={['0rem', null, '0rem']} fontSize={['15px', null, '20px']}> {bodyText}</Text>
                {bodyTextList && <UnorderedList spacing={3}>
                    {bodyTextList.map((x) => <ListItem fontSize={['15px', null, '20px']}>{x}</ListItem>)}
                </UnorderedList>
                }
            </Flex>
        </HStack>
                </div>
    )
}

export default InfoTile