import { Image } from '@chakra-ui/react';


interface CarouselImageProps {
    filePath : string
    altText: string
    imageHeight: string
}

export const CarouselImage = ({filePath, altText, imageHeight}: CarouselImageProps) => {
    return (
        <Image 
            objectFit={'cover'}
            width='100%'
            height={imageHeight}
            alt={altText}
            src={filePath}
        />
    )
}
