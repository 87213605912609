import ContactCustomerData from './models/ContactData/ContactCustomerData'
import axios from 'axios';

const environment: string = 'prod'
const email_sender_url: string = 'https://e2spwfv2rn4uelxcye5bdfbuwi0kcnkq.lambda-url.us-east-1.on.aws/'

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");

    return number.substring(0, 1) + "+ " + number.substring(1).replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
};


export function submit_to_email_sender(body: ContactCustomerData, callback: any) {

    if (environment !== 'prod') {
        console.log(`request will not submit for local-dev: login to console`)
        console.log(body)
        return null
    }

    let headers = {
        headers : {
            'Content-Type' : "application/json"
        }
    }

    console.log(body)

    let email_payload = {
        recipient: "office@wcrent.com",
        subject: "WCRENT - New Client Request",
        purpose: "Contact Us",
        content: body,
    }

    //TODO: use promise for "please wait, while loading" screen later
    axios.post(email_sender_url, email_payload, headers)
        .then(function (response) {
            // TODO: Add response headers from backend to log response 200
            console.log(`API: Send Email Response ${response.status}`);
            callback(true)
        })
        .catch(function (error) {
            // handle error
            console.log('API: Could not submit to email sender' + error);
            console.log(error);
            callback(false)
        })
}
