
import { GridItem } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"

interface MobileQuotesProps {
    handleContactUs : () => void;
}
export const MobileQuotes = ({handleContactUs}: MobileQuotesProps) =>  {
    return (
        <GridItem colSpan={4} display={['flex', 'flex', 'None']} justifyContent={'center'} py='1rem'>
            <Button colorScheme='teal' variant='ghost' onClick={handleContactUs}>
                Request a Quote!
            </Button>
        </GridItem> 
    )
}