import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { useContext } from 'react';
import { siteSettingsContext } from '../../context/SiteSettingsContext';
import SiteFooterSection from '../../models/SiteData/SiteFooterSection';
import { Box, Text } from '@chakra-ui/react';

const SiteFooter = () => {

  const { siteFooter, contactInfo } = useContext(siteSettingsContext);

  return (
    <MDBFooter bgColor='light' className='text-muted' style={{width : '100%'}}>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
      </section>

      <section>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
              {siteFooter.sections.map((section : SiteFooterSection) => {
                return (<MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                    <Text fontWeight={800}>{section.header}</Text>
                    {section.links.map((x) => <Text>
                      {/* {x.icon && (x.icon === 'mail' ? <EmailIcon/>  : <PhoneIcon/> ) } */}
                      {x.label
                      .replace('#email', contactInfo.email)
                      .replace('#phone', contactInfo.phoneNumber)}
                    </Text>)}
                </MDBCol>)
              } )}
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2021 Copyright:
        <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
          {siteFooter.copyright}
        </a>
      </div>
    </MDBFooter>
  );
}


export default SiteFooter