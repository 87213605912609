import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ChangeEvent } from 'react'
import { phoneNumberAutoFormat } from '../../utils'
import ContactCustomerData from '../../models/ContactData/ContactCustomerData';
import { Row, Col, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useContext } from 'react';
import { siteSettingsContext } from '../../context/SiteSettingsContext';

interface ContactUsProps {
    isOpen: boolean
    handleClose : any
    handleSubmit: any
}

const ContactUs = (props : ContactUsProps) => {

    const [name, set_name] = useState("");
    const [email, set_email] = useState("");
    const [phoneNumber, set_phoneNumber] = useState("");
    const [address, set_address] = useState("");
    const [a_city, set_a_city] = useState("");
    const [a_state, set_a_state] = useState("");
    const [a_zip, set_a_zip] = useState("");
    const [service, set_service] = useState("QUOTES / ESTIMATION");
    const [serviceDescription, set_serviceDescription] = useState("");

    const update_name = (event: any) => set_name(event.target.value)
    const update_email = (event: any) => set_email(event.target.value)
    const update_a_city = (event: any) => set_a_city(event.target.value)
    const update_a_state = (event: any) => set_a_state(event.target.value)
    const update_a_zip = (event: any) => set_a_zip(event.target.value)
    const update_address = (event: any) => set_address(event.target.value)
    const update_service = (event: any) => set_service(event.target.value)
    const update_serviceDescription = (event: any) => set_serviceDescription(event.target.value)

    const { contactMenu } = useContext(siteSettingsContext)

    const update_phoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const targetValue = phoneNumberAutoFormat(e.target.value);
        set_phoneNumber(targetValue);
    };

    const handleSubmission = () => {

        let clientInfo: ContactCustomerData = {
            clientName: name,
            clientEmail: email,
            clientPhone: phoneNumber,
            serviceType: service,
            serviceAddress: address,
            city: a_city,
            state: a_state,
            zipCode: a_zip,
            description: serviceDescription
        }

        props.handleSubmit(clientInfo);

    }

    return (
        <>
            <Modal show={props.isOpen} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us!</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Form className="RequestorForm">
                        <Row id='ContactFormSection1' className="mb-3">
                            <Col>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control type="text" placeholder="Name or Business Name" onChange={update_name} />
                            </Col>
                            <Col>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="text" placeholder="example@gmail.com" onChange={update_email} />
                            </Col>
                        </Row>

                        <Row id='ContactFormSection2' className="mb-3">
                            <Col>
                                <Form.Label>Phone Number:</Form.Label>
                                <Form.Control type="text" placeholder="800-555-1999" value={phoneNumber} onChange={update_phoneNumber} />
                            </Col>
                            <Col>
                                {/* Empty Block */}
                            </Col>
                        </Row>

                        <Row id='ContactFormSection3' className="mb-3">
                            <Col>
                                <>
                                    <Form.Label>Service Type:</Form.Label>
                                    <Form.Select onChange={update_service}>
                                        {contactMenu.contactTypeOptions.map(d => (<option key={d}>{d}</option>))}
                                    </Form.Select>
                                </>
                            </Col>
                        </Row>

                        <Row id='ContactFormSection4' className="mb-3">
                            <Col>
                                <Form.Label>Service Address:</Form.Label>
                                <Form.Control type="text" placeholder="ex: Address where you need service" onChange={update_address} />
                            </Col>
                        </Row>
                        
                        <Row id='ContactFormSection4' className="mb-3">
                            <Col>
                                <Form.Label>City:</Form.Label>
                                <Form.Control type="text" placeholder="ex: Atlanta" onChange={update_a_city} />
                            </Col>
                            <Col>
                                <Form.Label>State:</Form.Label>
                                <Form.Control type="text" placeholder="ex: Georgia" onChange={update_a_state} />
                            </Col>
                        </Row>

                        <Row id='ContactFormSection4' className="mb-5">
                            <Col>
                                <Form.Label>Zip Code:</Form.Label>
                                <Form.Control type="text" placeholder="00000" onChange={update_a_zip} />
                            </Col>
                            <Col>
                                {/* Empty Section */}
                            </Col>
                        </Row>

                        <Row id='ContactFormSection4' className="mb-3">
                            <Form.Label>Please describe your request as best you can.</Form.Label>
                            <Form.Control as="textarea" rows={5} onChange={update_serviceDescription} />
                        </Row>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmission}>
                        Submit!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
  }
  
export default ContactUs