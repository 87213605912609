import CompanyLogo from './WCR-Enterprise-Logo-Transparent.png'
import image1 from './comm-construct-1.jpg'
import image2 from './construction-site.mp4'
import image3 from './luxury-house.jpg'

import bathroom from './bathroom-remodeling-cropped.png'
import sink from './sink-counter-top-cropped.png'
import floor from './wood-flooring-cropped.png'

export const appImages = {
    'CompanyLogo' : CompanyLogo,
    'image1' : image1,
    'image2' : image2,
    'image3' : image3,
    'bathroom' : bathroom,
    'sink' : sink,
    'floor' : floor,
}