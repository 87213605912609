

export const HeaderLinkDivider = () => {
    return (
        <div 
            style={{
                width: '1px',
                height: '28px',
                background: 'grey',
                marginLeft : '1vh',
                marginRight : '1vh'
            }}
        ></div>
    )
}

export default HeaderLinkDivider